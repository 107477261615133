import React from "react";
import { VStack, Text, Columns, Box, Column } from "@sqymagma/elements";

import { useAuth, useApp } from "@contexts";
import { getGreeting, getToday } from "./utils";
import {
  CalendarWidget,
  Emails,
  RecentTools,
  Taxonomy,
  MeetingsWidget,
  WeLog,
  Japi,
  CamelSteps,
  CamelsWidget,
  VirtualCoffee,
  ApplauseWidget,
  MoodWidget,
  WrappedWidget,
  UrlShortenerWidget,
  LastMoods,
} from "@components";
import { IconLink } from "@elements";

import * as S from "./style";

const Home = () => {
  const { user } = useAuth();
  const { recentTools, reward, fireReward, showWrappedWidget } = useApp();

  const today = getToday();

  return (
    <Columns
      hs="m"
      vs={{ default: "xs", s: "m" }}
      overflow={{ default: "hidden", s: "visible" }}
    >
      {/* Column #1 */}
      <Column
        width={[1, 1, "50%", "50%", "33%"]}
        pb={{ default: "xs", s: 0, xl: "m" }}
        order={{ default: 3, s: 1 }}
      >
        <VStack gap={{ default: 0, s: "s" }}>
          <Box display={{ default: "none", s: "block" }}>
            <VStack gap="xxs">
              <Text textStyle="subtitle01" color="text01">
                {today}
              </Text>
              <S.Greeting
                $rewardType={reward.type}
                onClick={reward.type && fireReward}
              >
                {getGreeting(reward, user)}
              </S.Greeting>
            </VStack>
          </Box>

          <VStack gap={{ default: "xs", s: "m" }}>
            {showWrappedWidget && (
              <Box display={{ default: "none", s: "block" }}>
                <WrappedWidget />
              </Box>
            )}
            {new Date().getDay() === 1 && <CamelSteps />}
            <MoodWidget />
            <LastMoods />
            <Columns
              cols={{ xs: 2, s: 2, m: 1, l: 2 }}
              hs="xs"
              vs="xs"
              overflow="visible"
              position="relative"
            >
              <S.MiniWidgetBox bg="#A950CF">
                <Japi />
              </S.MiniWidgetBox>
              <S.MiniWidgetBox bg="#3CC3DD">
                <Taxonomy />
              </S.MiniWidgetBox>
              <S.MiniWidgetBox bg="#2FCC83">
                <WeLog />
              </S.MiniWidgetBox>
              <S.MiniWidgetBox bg="#FFB84C">
                <CamelsWidget />
              </S.MiniWidgetBox>
            </Columns>
          </VStack>
        </VStack>
      </Column>

      {/* Column #2 */}
      <Column
        width={[1, 1, "50%", "50%", "33%"]}
        pb={{ default: 0, xl: "m" }}
        order={{ default: 2, s: 2 }}
      >
        <VStack gap={{ default: "xs", s: "m" }}>
          <Emails />
          <UrlShortenerWidget />
          <ApplauseWidget
            actionElements={
              <IconLink
                icon="barChart"
                to="/applauses"
                tooltip="Timeline y estadísticas"
              />
            }
          />
          {recentTools.length && <RecentTools />}
        </VStack>
      </Column>

      {/* Column #3 */}
      <Column
        width={[1, 1, "50%", "50%", "34%"]}
        pb={{ default: 0, s: "m" }}
        order={{ default: 1, s: 3 }}
      >
        <VStack gap={{ default: "xs", s: "m" }}>
          {showWrappedWidget && (
            <Box display={{ s: "none" }}>
              <WrappedWidget />
            </Box>
          )}
          <Box>
            <VirtualCoffee />
            <CalendarWidget />
          </Box>
          <MeetingsWidget />
        </VStack>
      </Column>
    </Columns>
  );
};

export default Home;
